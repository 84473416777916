<template>
  <div class="feedback-wrap">
    <div class="feedback-field">
      <Field
        v-model="message"
        rows="10"
        autosize
        type="textarea"
        maxlength="500"
        placeholder="请输您的建议，需求与反馈"
        show-word-limit
      />
    </div>
    <div class="submit" @click="submitHandle">提交</div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Field, Toast } from 'vant'
import { feedbackApi } from '@/api/'
export default {
  components: {
    Field
  },
  setup () {
    const message = ref('')
    const router = useRouter()

    const submitHandle = () => {
      console.log('提交')
      if (message.value === '') {
        Toast('请输入相应内容后提交!')
      } else if (message.value.length < 5) {
        Toast('请至少输入5个字符')
      } else {
        feedbackApi({
          content: message.value
        }).then(res => {
          console.log(res)
          if (res.code === 200) {
            Toast.success({
              message: '提交成功，感谢您的反馈！',
              onClose: () => {
                router.back()
              }
            })
          }
        })
      }
    }

    return {
      message,
      submitHandle
    }
  }
}
</script>

<style lang="less" scoped>
.feedback-wrap {
  padding: 12px 15px 0 15px;
  .feedback-field {
    border-radius: 4px;
    border: 1px solid #E7E7E7;
  }
  .submit {
    width: 303px;
    height: 44px;
    background: #0C86FE;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 74px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
</style>
